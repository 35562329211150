<script lang="tsx">
import type { PropType, SlotsType } from 'vue'

export type BaseUiFormErrorProps<T> = {
    errorId?: string
    message?: string
    error?: GlobalFormError
}

type BaseUiFormErrorSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormError<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormErrorProps<T>, BaseUiFormErrorSlots<T>>) {
    return defineComponent(
        (props: BaseUiFormErrorProps<T>, ctx) => {

            return () => (props.error?.id || !props.error) ? (
                <div
                    id={props.errorId ?? props.error?.id}
                    class="sim-error"
                    role="alert"
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                        <>
                            {props.message ?? props.error?.message}
                        </>
                    ))}
                </div>
            ) : null
        },
        {
            props: {
                errorId: {
                    type: String as PropType<BaseUiFormErrorProps<T>['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },
                message: {
                    type: String as PropType<BaseUiFormErrorProps<T>['message']>,
                    default: options?.props?.message?.default,
                    required: options?.props?.message?.required ?? false,
                },
                error: {
                    type: Object as PropType<BaseUiFormErrorProps<T>['error']>,
                    default: options?.props?.error?.default,
                    required: options?.props?.error?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormErrorSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormError()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormError.scss" as *;

</style>
